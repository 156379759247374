<template>
  <div id="imageItem">
    <!-- <figure v-lazyload> 
    <img class="image__item" :data-url="source" loading="lazy" alt />
     <img class="image__item" v-else :data-url="source" loading="lazy" alt /> -->
  <!-- </figure> -->
  <figure class="image__wrapper">
    <img class="image__item" :src="source" :onerror="cardType === 'LANDSCAPE' ? placeholder_lands : placeholder_port" loading="lazy" alt="category Image" :title="title"/>
  </figure>
  </div>
 
</template>

<script>
import LazyLoad from "../../directives/lazyLoad.js";

export default {
  name: "imageItem",
  props: ['source', 'title', 'cardType'],
  data() {
    return {
      posterChange: true,
      placeholder_port: require('@/assets/placeholder-port.svg'),
      placeholder_lands: require('@/assets/placeholder-lands.svg'),
    };
  },
  watch: {
    source(val) {
      if (val) {
        this.posterChange = !this.posterChange;
      }
    },
  },
  directives: {
    lazyload: LazyLoad,
  },
};
</script>

<style lang="scss" scoped>
@import "./imageItem.scss"
</style>
